import authAxios from '@/shared/axios/auth-axios';

export class HomeService {
    static async getProjectsByFaculty() {        
        const response = await authAxios.get(`/report/by-faculty`);
        return response.data;
    }

    static async getProjectsByStatus() {        
        const response = await authAxios.get(`/report/by-status`);
        return response.data;
    }

    static async getProjectsByFunding() {        
        const response = await authAxios.get(`/report/by-funding`);
        return response.data;
    }
}
