<template>
  <div>
    <div v-if="!loading" class="app-content-page">
      <div v-if="!isMobile">
        <el-tabs>
          <el-tab-pane label="Statistics">
            <el-row>
              <el-col :span="14" style="padding: 10px">
                <div style="padding: 10px">
                  <h1 class="app-content-title">
                    <app-i18n code="entities.project.list.title"></app-i18n>
                  </h1>
                  <app-project-list-table
                    :inHomePage="true"
                  ></app-project-list-table>
                </div>

                <div style="padding: 10px">
                  <h1 class="app-content-title">
                    <app-i18n
                      code="entities.knowledgeBase.list.title"
                    ></app-i18n>
                  </h1>
                  <app-knowledge-base-list-table
                    :inHomePage="true"
                  ></app-knowledge-base-list-table>
                </div>
              </el-col>
              <el-col :span="10" style="padding: 10px">
                <div style="padding: 10px">
                  <app-home-chart :config="projectsByStatus" />
                </div>
                <div style="padding: 10px">
                  <app-home-chart :config="projectsByFaculty" />
                </div>
                <div style="padding: 10px">
                  <app-home-chart :config="projectsByFunding" />
                </div>
              </el-col>
            </el-row>
            <p class="home-bottom-message">
              <app-i18n code="home.message"></app-i18n>
            </p>
          </el-tab-pane>

          <el-tab-pane label="To-Do" v-if="isAdmin">
            <app-to-do></app-to-do>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-else style="padding-top: 40px">
        <el-row>
          <el-col>
            <div style="padding: 10px">
              <app-project-list-table
                :inHomePage="true"
              ></app-project-list-table>
            </div>
            <div style="padding: 10px">
              <app-home-chart :config="projectsByStatus" />
            </div>
            <div style="padding: 10px">
              <app-home-chart :config="projectsByFaculty" />
            </div>
            <div style="padding: 10px">
              <app-home-chart :config="projectsByFunding" />
            </div>
            <div style="padding: 10px">
              <app-knowledge-base-list-table
                :inHomePage="true"
              ></app-knowledge-base-list-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import HomeChart from '@/modules/home/components/home-chart.vue';
import ProjectListTable from '@/modules/project/components/project-list-table.vue';
import KnowledgeBaseListTable from '@/modules/knowledge-base/components/knowledge-base-list-table.vue';
import ToDo from '@/modules/home/components/to-do.vue';
import { HomeService } from '@/modules/home/home-service.js';
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-home-page',

  components: {
    [HomeChart.name]: HomeChart,
    [ProjectListTable.name]: ProjectListTable,
    [KnowledgeBaseListTable.name]: KnowledgeBaseListTable,
    [ToDo.name]: ToDo,
  },

  async created() {
    if (this.isMobile) {
      this.collapseMenu();
    }
    this.loading = true;
    await this.doGetProjectsByFaculty();
    await this.doGetProjectsByStatus();
    this.loading = false;
  },

  mounted() {
    this.doFetchProjects({
      filter: {
        status: [
          'CREATED/PROJECT_RELEASE_OPEN',
          'CREATED/REQUESTED_FOR_RELEASE',
          'RUNNING',
          'CREATED/REVISION_REQUESTED',
        ],
      },
    });
    this.doFetchKnowledgeBase();
    this.doGetProjectsByFunding();
  },

  data() {
    return {
      loading: false,
      projectsByFaculty: {},
      projectsByStatus: {},
      projectsByFunding: {},
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      isAdmin: 'auth/isAdmin',
    }),
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetchProjects: 'project/list/doFetch',
      doFetchKnowledgeBase: 'knowledgeBase/list/doFetch',
    }),

    async doGetProjectsByFaculty() {
      const records = await HomeService.getProjectsByFaculty();

      const labels = Object.keys(records).map((k) => `${k}`);
      const data = Object.values(records).map((v) => v);

      this.projectsByFaculty = {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
          ],
        },
      };
    },

    async doGetProjectsByStatus() {
      const records = await HomeService.getProjectsByStatus();
      const labels = Object.keys(records).map(
        (k) => `${i18n(`entities.project.enumerators.status.${k}`)}`,
      );
      const data = Object.values(records).map((v) => v);

      this.projectsByStatus = {
        type: 'polarArea',
        data: {
          datasets: [
            {
              data,
              backgroundColor: [
                '#FF6384',
                '#4BC0C0',
                '#FFCE56',
                '#E7E9ED',
                '#36A2EB',
              ],
            },
          ],
          labels,
        },
      };
    },

    async doGetProjectsByFunding() {
      const records = await HomeService.getProjectsByFunding();

      const labels = Object.keys(records).map(
        (k) =>
          `${i18n(`entities.projectFunding.enumerators.fundingEnum.${k}`)}`,
      );
      const data = Object.values(records).map((v) => v);

      this.projectsByFunding = {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
          ],
        },
      };
    },
  },
};
</script>

<style scoped>
.el-col {
  margin-bottom: 16px;
}

.home-bottom-message {
  width: 100%;
  text-align: center;
  color: grey;
}
</style>
