<template>
  <el-row :gutter="5">
    <p style="text-align: center;">
      <app-i18n code="toDo.name"></app-i18n>
    </p>
    <el-col :span="6">
      <app-to-do-card
        :loading="loadingPendingSignedDocuments"
        :items="pendingSignedDocuments"
      >
        <template v-slot:title>
          <app-i18n
            code="toDo.headers.pendingSignedDocuments"
          ></app-i18n>
        </template>
      </app-to-do-card>
    </el-col>
  </el-row>
</template>

<script>
import ToDoCard from '@/modules/home/components/to-do-card.vue';
import { ProjectReleaseService } from '@/modules/project-release/project-release-service';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-to-do',
  components: {
    [ToDoCard.name]: ToDoCard,
  },
  data() {
    return {
      pendingSignedDocuments: null,
      loadingPendingSignedDocuments: false,
    };
  },
  async created() {
    this.doFetchReleasesWithPendingSignedDocUpload();
  },

  methods: {
    async doFetchReleasesWithPendingSignedDocUpload() {
      try {
        this.loadingPendingSignedDocuments = true;
        this.pendingSignedDocuments = await ProjectReleaseService.listPending();
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loadingPendingSignedDocuments = false;
      }
    },
  },
};
</script>

<style></style>
